<template>
  <div style="margin-top:20px">
    <div class="search_flex">
      <div class="search_left">
        <selectComp
          :hval="status"
          :data="statusList"
          @change="statusChange"
          placeholder="品控状态"
        />
        <selectComp
          :hval="orderStatus"
          :data="orderStatusList"
          @change="orderStatusChange"
          placeholder="工单状态"
        />
        <selectComp
          :hval="priceType"
          :data="priceTypeList"
          @change="priceTypeChange"
          placeholder="处罚金额"
        />
        <selectComp
          v-if="!isStoreRole"
          :data="itemChangeList"
          :hval="itemChange"
          @change="itemChangeChange"
          placeholder="品控是否有变更"
        />
        <merchant :hval="storeName" @storeNameChange="storeNameChange" />
        <inputComp :hval="code" @blur="codeBlur" placeholder="工单编号" />
        <inputComp
          :hval="orderCode"
          @blur="orderCodeBlur"
          placeholder="订单编号"
        />
        <dateComp
          :hval="date"
          startPlaceholder="报修开始日期"
          endPlaceholder="报修结束日期"
          @change="dateChange"
        />
        <dateComp
          :hval="deliverDate"
          startPlaceholder="发货开始日期"
          endPlaceholder="发货结束日期"
          @change="deliverDateChange"
        />
        <btnGroup @search="search" @reset="reset" />
        <btnComp
          :disabled="allotDisabled"
          btnType="plain"
          @click="batchHandle(1)"
          v-permission="'批量复查'"
          >批量复查</btnComp
        >
        <btnComp
          :disabled="allotDisabled"
          btnType="plain"
          @click="batchHandle(3)"
          v-permission="'批量确认'"
          >批量确认</btnComp
        >
        <btnComp
          :disabled="allotDisabled"
          btnType="plain"
          @click="batchHandle(4)"
          v-permission="'批量结算'"
          >批量结算</btnComp
        >
        <btnComp @click="exportData" v-permission="'导出列表'"
          >导出列表</btnComp
        >
      </div>
    </div>
    <tableComp
      v-loading="loading"
      :data="tableData"
      :col-configs="theadName"
      :total="total"
      :currentPage="curPage"
      :pageSize="pageSize"
      @select="select"
      isShowCheckBox
      @selectAll="selectAll"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column slot="statusName" label="品控状态">
        <template v-slot="scope">
          <div>{{ scope.row.statusName }}</div>
          <div
            v-if="scope.row.itemChange && !isStoreRole"
            style="color:#ff6700"
          >
            有变更
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="num" label="处罚数量/开箱不良/报修数量">
        <template v-slot="scope">
          <span>
            {{ scope.row.punishCount || '-' }} /
            {{ scope.row.poorUnpackingCount || '-' }} /
            {{ scope.row.amount || '-' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" fixed="right" width="130">
        <template v-slot="scope">
          <btnComp
            style="margin-left:10px"
            btnType="text"
            @click="audit(scope.row)"
            v-permission="'进入审核'"
            >进入审核</btnComp
          >
          <btnComp
            btnType="text"
            @click="audit(scope.row)"
            v-permission="'进入商家确认'"
            >进入商家确认</btnComp
          >
          <btnComp
            v-if="scope.row.status === 1"
            btnType="text"
            @click="reverse(scope.row, '反复查')"
            v-permission="'反复查'"
            >反复查</btnComp
          >
          <btnComp
            v-if="scope.row.status === 3"
            btnType="text"
            @click="reverse(scope.row, '反审核')"
            v-permission="'反审核'"
            >反审核</btnComp
          >
        </template>
      </el-table-column>
    </tableComp>
    <!-- 详情弹窗 -->
    <drawerDetail @close="drawerClose" />
    <dialogComp title="提示" :isShow="hintShow" @close="close" @sure="hintSure">
      <p>{{ `确定${hintContent}吗？` }}</p>
    </dialogComp>
  </div>
</template>

<script>
import drawerDetail from '@/views/work-order/all-order/detail'
import merchant from '@/views/components/merchant'
import { qcList, qcListExport, qcBatchSettle, reverseCheckOrAudit } from '@/api'
import { mapMutations, mapState } from 'vuex'
import {
  orderStatusListToQC,
  statusListToQC,
  priceTypeList
} from '@/utils/constData'
export default {
  components: {
    drawerDetail,
    merchant
  },
  props: {
    // tabs类型
    tabType: {
      type: String,
      default: 'entrust'
    }
  },
  data () {
    return {
      loading: false,
      hintShow: false,
      selectIds: [],
      hintContent: '', // 提示框动态内容
      curPage: 1,
      pageSize: 10,
      total: 0,
      status: '',
      orderStatus: '',
      code: '',
      orderCode: '',
      storeId: '',
      storeName: '',
      priceType: '',
      itemChange: '',
      startTime: '',
      endTime: '',
      date: [],
      deliverStartTime: '',
      deliverEndTime: '',
      deliverDate: [],
      itemChangeList: [
        { label: '有变更', value: true },
        { label: '无变更', value: false }
      ],
      tableData: [],
      theadName: [
        { prop: 'payStatusName', label: '支付状态' },
        { slot: 'statusName' },
        { prop: 'orderStatusName', label: '工单状态' },
        { prop: 'code', label: '报修工单号' },
        { prop: 'storeName', label: '商家' },
        { prop: 'categoryName', label: '设备分类' },
        { prop: 'orderCreatedTime', label: '报修时间' },
        { prop: 'typeName', label: '处理方式' },
        { prop: 'satisfactionName', label: '客户满意度' },
        { slot: 'num' },
        { prop: 'price', label: '处罚金额' },
        { slot: 'operate' }
      ]
    }
  },
  computed: {
    ...mapState(['drawerShow']),
    allotDisabled () {
      return !(this.selectIds.length > 0)
    },
    // 是否是商家角色
    isStoreRole () {
      return this.$store.state.loginUserInfo.businessRole === 'store'
    },
    // 定义常量
    orderStatusList () {
      return orderStatusListToQC
    },
    statusList () {
      return statusListToQC
    },
    priceTypeList () {
      return priceTypeList
    }
  },
  watch: {
    // 监听详情商家确认弹窗关闭刷新列表页面
    drawerShow: {
      handler (val) {
        if (!val) {
          this.getList()
        }
      }
    }
  },
  mounted () {
    // 商家默认是待确认
    this.status = this.isStoreRole ? 2 : ''
    this.getList()
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SHOW', 'SET_INDEX_INFO']),
    getList () {
      this.params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        storeSettlementType: this.tabType === 'entrust' ? 1 : 2,
        status: this.status,
        orderStatus: this.orderStatus,
        priceType: this.priceType,
        itemChange: this.itemChange,
        code: this.code,
        orderCode: this.orderCode,
        storeId: this.storeId,
        startTime: this.startTime,
        endTime: this.endTime,
        deliverStartTime: this.deliverStartTime,
        deliverEndTime: this.deliverEndTime
      }
      this.loading = true
      qcList(this.params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.tableData = Object.freeze(list)
        this.total = Object.freeze(totalCount)
      })
    },
    codeBlur (val) {
      this.code = val
    },
    orderCodeBlur (val) {
      this.orderCode = val
    },
    statusChange (val) {
      this.status = val
    },
    orderStatusChange (val) {
      this.orderStatus = val
    },
    priceTypeChange (val) {
      this.priceType = val
    },
    itemChangeChange (val) {
      this.itemChange = val
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    deliverDateChange (startTime, endTime) {
      this.deliverStartTime = startTime
      this.deliverEndTime = endTime
    },
    // 导出
    exportData () {
      qcListExport(this.params)
      this.$message({
        message:
          '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    },
    // 反复查和反审核
    reverse (info, title) {
      this.info = info
      this.hintContent = title
      this.hintShow = true
    },
    // 审核和商家确认
    audit (info) {
      const { orderId, categoryId, orderCode } = info
      this.SET_DRAWER_SHOW(true)
      this.SET_INDEX_INFO({ id: orderId, categoryId, orderCode })
    },
    // BZ210817195311421-D2
    batchHandle (status) {
      this.hintContent =
        status === 1 ? '批量复查' : status === 3 ? '批量确认' : '批量结算'
      this.batchStatus = status
      this.hintShow = true
    },
    hintSure () {
      //  确认批量复查、确认、结算
      if (
        this.hintContent === '批量复查' ||
        this.hintContent === '批量确认' ||
        this.hintContent === '批量结算'
      ) {
        const idList = this.selectData.map(item => ({
          id: item.id,
          code: item.code,
          orderId: item.orderId
        }))
        // 1：复查，2：审核，3：确认，4：结算
        const status = this.batchStatus
        const params = { idList, status }
        qcBatchSettle(params).then(_ => {
          this.$message.success(this.hintContent + '成功')
          this.close()
        })
        return
      }
      // 反复查和反审核
      const { id, status } = this.info
      // 0：反复查，1：反审核
      const updateStatus = status === 1 ? 0 : status === 3 ? 1 : null
      reverseCheckOrAudit({ id, status: updateStatus }).then(_ => {
        this.$message.success(this.hintContent + '成功')
        this.close()
      })
    },
    close () {
      this.selectIds = []
      this.hintShow = false
      this.getList()
    },
    drawerClose () {
      this.SET_DRAWER_SHOW(false)
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.status = this.isStoreRole ? 2 : ''
      this.orderStatus = ''
      this.priceType = ''
      this.itemChange = ''
      this.code = ''
      this.orderCode = ''
      this.storeId = ''
      this.storeName = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.deliverDate = []
      this.deliverStartTime = ''
      this.deliverEndTime = ''
      this.getList()
    },
    select (val) {
      this.selectIds = val.map(item => item.id)
      this.selectData = val
    },
    selectAll (val) {
      this.selectIds = val.map(item => item.id)
      this.selectData = val
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
