<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con" style="padding-top:10px">
        <el-tabs class="tabs" v-model="activeName" style="padding-left:0">
          <el-tab-pane label="平台托管单" name="entrust">
            <comp :tabType="activeName" v-if="activeName==='entrust'" />
          </el-tab-pane>
          <el-tab-pane label="商家自管单" name="self">
            <comp :tabType="activeName" v-if="activeName==='self'" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import comp from './comp'
export default {
  components: {
    comp
  },
  data () {
    return {
      activeName: 'entrust'
    }
  }
}
</script>
